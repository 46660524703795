import { parse, serialize, CookieSerializeOptions } from "cookie";

import { type AppLoadContext } from "types/common";

import { callWithAsyncContext } from "./async";

export interface CookieStorage {
  get(name: string): string | null;
  set(name: string, value: string, options?: CookieSerializeOptions): void;
  getAll(): Record<string, string>;
  delete(name: string): void;
}

export class ServerCookie implements CookieStorage {
  private setCookiesList: string[] = [];

  constructor(private cookie: string | null) {}

  public get(name: string): string {
    return parse(this.cookie || "")[name];
  }

  public get setCookies() {
    return this.setCookiesList;
  }

  public set(
    name: string,
    value: string,
    options?: CookieSerializeOptions,
  ): void {
    this.setCookiesList.push(serialize(name, value, options));
  }

  public getAll(): Record<string, string> {
    return parse(this.cookie || "");
  }

  public delete(name: string): void {
    this.setCookiesList.push(
      serialize(name, this.get(name), { expires: new Date(0), path: "/" }),
    );
  }
}

class ClientCookie implements CookieStorage {
  public get(name: string): string {
    return this.getAll()[name];
  }

  public set(
    name: string,
    value: string,
    options?: CookieSerializeOptions,
  ): void {
    document.cookie = serialize(name, value, options);
  }

  public getAll(): Record<string, string> {
    return parse(document.cookie);
  }

  public delete(name: string): void {
    const cookie = parse(document.cookie || "");
    if (name in cookie) {
      this.set(name, cookie[name], { expires: new Date(0), path: "/" });
    }
  }
}

export const callCookiesContext = <A extends unknown[], R>(
  fn: (...args: A) => R,
  context: unknown,
): R => {
  const { serverCookies } = context as AppLoadContext;

  return callWithAsyncContext(fn, serverCookies);
};

export const getCookies = (): CookieStorage => {
  if ("asyncStore" in globalThis) {
    return globalThis.asyncStore.getStore();
  }

  return new ClientCookie();
};
