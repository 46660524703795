import { ComponentType, useContext, useEffect, useMemo } from "react";
import { ReactReduxContext } from "react-redux";
import { UNSAFE_DataRouterContext } from "react-router-dom";
import { isEmpty, isUndefined } from "lodash";
import {
  AsyncThunkFulfilledActionCreator,
  AsyncThunkPendingActionCreator,
  AsyncThunkRejectedActionCreator,
} from "@reduxjs/toolkit/dist/createAsyncThunk";
import { createAction } from "@reduxjs/toolkit";

import { AppStore } from "store/duck/types";
import { RootState } from "store/duck/types";

import { Promises, ServerThunkPromise } from "types/common";

import { IS_SERVER } from "constants/env";

import { getSettledValue } from "selectors/redux";

type Payload = Record<
  string,
  PromiseSettledResult<unknown> | Promise<PromiseSettledResult<unknown>>
>;
type ValuesMap = Record<string, Payload>;

const createActionType = <T extends string>(
  typePrefix: T,
  additional: "pending" | "fulfilled" | "rejected",
): `${T}/${typeof additional}` => `${typePrefix}/${additional}`;

const createDispatchAction =
  store => (payload: PromiseSettledResult<unknown>, type: string) => {
    const isFulfilled = payload.status === "fulfilled";
    const action: { type: string; error?: Error; payload?: unknown } = {
      type: createActionType(type, payload.status),
    };
    if (isFulfilled) {
      action.payload = payload.value;
    } else {
      action.error = payload.reason;
    }

    store.dispatch(action);
  };

const reduceData = (data: ValuesMap, def: Payload) =>
  Object.values(data).reduce((acc, src) => Object.assign(acc, src), { ...def });

const isEqualResults = (
  first: PromiseSettledResult<unknown> | Promise<PromiseSettledResult<unknown>>,
  second:
    | PromiseSettledResult<unknown>
    | Promise<PromiseSettledResult<unknown>>,
) => {
  if (!first || !second) {
    return false;
  }

  if (first instanceof Promise || second instanceof Promise) {
    return first !== second;
  }

  return Object.entries(first).every(([key, value]) => second[key] === value);
};

const getChangedValues = (data: ValuesMap, memo: Payload): Payload | null => {
  const dataObject = reduceData(data, {});
  const updates: Payload = {};
  for (const type in dataObject) {
    if (!isEqualResults(dataObject[type], memo[type])) {
      updates[type] = dataObject[type];
    }
  }

  return isEmpty(updates) ? null : updates;
};

const serverSync = (Component: ComponentType) => () => {
  const { store } = useContext(ReactReduxContext);
  const context = useContext(UNSAFE_DataRouterContext);
  const data = reduceData(context.router.state.loaderData, {});
  const dispatchAction = createDispatchAction(store);

  for (const type in data) {
    const payload = data[type];
    if (payload instanceof Promise) {
      store.dispatch({
        type: createActionType(type, "pending"),
        payload: null,
      });
    } else {
      dispatchAction(payload, type);
    }
  }

  return <Component />;
};

const clientSync = (Component: ComponentType) => () => {
  const { store } = useContext(ReactReduxContext);
  const context = useContext(UNSAFE_DataRouterContext);
  const dispatchAction = useMemo(() => createDispatchAction(store), [store]);

  useEffect(() => {
    let memoLoaderData = reduceData(context.router.state.loaderData, {});

    const handlePromise = (
      promise: Promise<PromiseSettledResult<unknown>>,
      type: string,
    ) => {
      promise.then(payload => {
        memoLoaderData[type] = payload;

        dispatchAction(payload, type);
      });
    };

    for (const type in memoLoaderData) {
      const payload = memoLoaderData[type];

      if (payload instanceof Promise) {
        handlePromise(payload, type);
      }
    }

    return context.router.subscribe(({ loaderData }) => {
      const updates = getChangedValues(loaderData, memoLoaderData);
      if (!updates) {
        return;
      }

      memoLoaderData = Object.assign(memoLoaderData, updates);

      for (const type in updates) {
        const payload = updates[type];
        if (payload instanceof Promise) {
          handlePromise(payload, type);
        } else {
          dispatchAction(payload, type);
        }
      }
    });
  }, []);

  return <Component />;
};

export default IS_SERVER ? serverSync : clientSync;

interface ThunkOptions {
  promises?: Promises;
}

interface ThunkCallback<R, A> {
  (arg: A, options?: ThunkOptions): R | Promise<R>;
}

interface CreateThunkOptions<R, A> {
  selectResult(state: RootState, arg: A): R | void;
}

interface Thunk<R, A, T extends string> {
  (arg: A, options?: ThunkOptions): ServerThunkPromise<R>;
  typePrefix: T;
  pending: AsyncThunkPendingActionCreator<A>;
  fulfilled: AsyncThunkFulfilledActionCreator<R, A>;
  rejected: AsyncThunkRejectedActionCreator<A>;
  wait(promises: Promises): Promise<Awaited<R> | null>;
  getValue<T extends Record<string, unknown>>(data: T): R | undefined;
}

interface MemoThunk<R, A, T extends string> extends Thunk<R, A, T> {
  selectResult(state: RootState, arg: A): PromiseSettledResult<R>;
}

export function createAsyncServerThunk<
  Result = unknown,
  Arg = void,
  T extends string = string,
>(typePrefix: T, callback: ThunkCallback<Result, Arg>): Thunk<Result, Arg, T>;

export function createAsyncServerThunk<
  Result = unknown,
  Arg = void,
  T extends string = string,
>(
  typePrefix: T,
  callback: ThunkCallback<Result, Arg>,
  options: CreateThunkOptions<Result, Arg>,
): MemoThunk<Result, Arg, T>;

export function createAsyncServerThunk(
  typePrefix: string,
  callback: ThunkCallback<unknown, unknown>,
  { selectResult }: Partial<CreateThunkOptions<unknown, unknown>> = {},
): unknown {
  const callPromise = (arg: unknown) => {
    const promise = callback(arg);
    const resultPromise = new Promise(async resolve => {
      try {
        resolve({
          status: "fulfilled",
          value: await promise,
        });
      } catch (error) {
        resolve({
          status: "rejected",
          reason: {
            ...error,
            name: error.name,
            stack: error.stack,
            message: error.message,
          },
        });
      }
    });

    return Object.assign(resultPromise, {
      unwrap: () => promise,
    }) as ServerThunkPromise<unknown>;
  };

  const thunk = (arg: unknown, { promises }: ThunkOptions = {}) => {
    const promise = callPromise(arg);

    if (promises) {
      promises[typePrefix] = promise;
    }

    return promise;
  };

  return Object.assign(thunk, {
    pending: createAction(createActionType(typePrefix, "pending")),
    fulfilled: createAction(createActionType(typePrefix, "fulfilled")),
    rejected: createAction(createActionType(typePrefix, "rejected")),
    typePrefix,
    selectResult: (state, arg) => {
      const value = selectResult(state, arg);
      if (isUndefined(value)) {
        return null;
      }

      return { status: "fulfilled", value };
    },
    wait: async (promises: Promises) =>
      getSettledValue(await promises[typePrefix]),
    getValue: data => data[typePrefix]?.value,
  });
}

interface ScenarioFunction<R, A extends unknown[] = void[]> {
  (
    api: {
      dispatch: AppStore["dispatch"];
      getState: AppStore["getState"];
      resolve: (value: R) => void;
    },
    ...args: A
  ): Promise<Awaited<R>> | void | Promise<void>;
}

interface Dispatch<R> {
  (dispatch: AppStore["dispatch"], getState: AppStore["getState"]): Promise<R>;
}

export const createScenario =
  <R, A extends unknown[] = void[]>(callback: ScenarioFunction<R, A>) =>
  (...args: A): Dispatch<R> =>
  (dispatch, getState) =>
    new Promise<R>(async resolve => {
      const result = await callback({ dispatch, getState, resolve }, ...args);
      if (!isUndefined(result)) {
        resolve(result as R);
      }
    });
