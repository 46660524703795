import servicesAPI from "api/services";

import { RegionsDictionary } from "./types";

export const loadRegionsIsomorphic = async () => {
  const regions = await servicesAPI.fetchAllRegions();
  const result: RegionsDictionary = {};
  for (const region of regions) {
    result[region.letterId] = {
      ...region,
      states: region.states.reduce(
        (acc, region) => Object.assign(acc, { [region.letterId]: region }),
        {},
      ),
    };
  }

  return result;
};
