import { NO_KYC_CATEGORY_ID } from "constants/insights";

import { instance } from "./instances/coindisco";

export interface FetchInsightsOption {
  cryptocurrency: Coin["id"];
  currency: Currency["id"];
  currencyAmount?: number;
  network: Network["id"];
  paymentMethod: PaymentMethod["id"];
  region: Region["id"];
}

interface FetchInsightQuote {
  insightId: Insight["id"];
  baseCurrency: Currency["id"];
  amount: number;
}

export const insightsAPI = new (class {
  public async fetchCategories(): Promise<InsightCategory[]> {
    const { data }: { data: InsightCategory[] } = await instance.get(
      "insights/v2/categories/",
    );

    return data;
  }

  public async fetchNoKYCCategory(
    args: FetchInsightsOption[],
  ): Promise<Insight[]> {
    const { data } = await instance.post(
      `insights/categories/${NO_KYC_CATEGORY_ID}/insights/`,
      args,
    );

    return data;
  }

  public async fetchInsights(args: FetchInsightsOption[]): Promise<Insight[]> {
    const { data } = await instance.post("insights/categories/insights/", args);

    return data;
  }

  public async fetchInsight(id: Insight["id"]): Promise<Insight> {
    const { data } = await instance.get(`insights/categories/insights/${id}/`);

    return data;
  }

  public async fetchInsightQuote({
    amount,
    baseCurrency,
    insightId,
  }: FetchInsightQuote): Promise<BuyProvider> {
    const { data } = await instance.post(
      `service-integration/search-buy-options/insight/${insightId}/`,
      {
        baseCurrency,
        currencyAmount: amount,
      },
    );

    return data;
  }
})();
