import { IS_SERVER } from "constants/env";

import { configure } from "./configure";

const getPreloadedState = () => {
  if (!IS_SERVER && "[REDUX_STATE]" in window) {
    return window["[REDUX_STATE]"];
  }

  return {};
};

// Use only in client side
const { store, watcher } = configure({
  preloadedState: getPreloadedState(),
});

export default store;
export { watcher };
