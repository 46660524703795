import coinsAPI from "api/coins";
import { insightsAPI } from "api/insights";

import { createAsyncServerThunk } from "operations/redux";

export const loadCoinServer = createAsyncServerThunk<
  BaseCoin,
  BaseCoin["letterId"]
>("buy/loadCoin", id => coinsAPI.fetchCoin(id), {
  selectResult: ({ buy: { isLoadedCoin, coin, coins, isLoadedCoins } }, id) => {
    if (isLoadedCoin && coin.letterId === id) {
      return coin;
    }

    if (isLoadedCoins) {
      return coins.find(coin => coin.letterId === id);
    }
  },
});

export const loadInsightServer = createAsyncServerThunk<Insight, Insight["id"]>(
  "buy/loadInsightServer",
  id => insightsAPI.fetchInsight(id),
  {
    selectResult({ buy }, insightId) {
      if (buy.isLoadedInsight && buy.insight?.id === insightId) {
        return buy.insight;
      }
    },
  },
);
