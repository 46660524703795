import coinsAPI from "api/coins";
import { createAsyncServerThunk } from "operations/redux";
import { cookiesStorageData } from "./duck/operations";

export const loadCategoriesServer = createAsyncServerThunk(
  "coins/loadCategories",
  async () => {
    let categories: Category[] = [
      { displayName: "All", id: "all" },
      { displayName: "Watchlist", id: "watchlist" },
    ];

    try {
      categories = categories.concat(await coinsAPI.fetchCategories());
    } catch {
      //
    }

    return categories;
  },
  {
    selectResult: ({ coins: { isLoadedCategories, categories } }) => {
      if (isLoadedCategories) {
        return categories;
      }
    },
  },
);

export const loadCoinServer = createAsyncServerThunk(
  "coins/loadCoinServer",
  async (id: string) => {
    const coin = await coinsAPI.fetchCoin(id);

    return {
      ...coin,
      isFavorite: coin.isFavorite || cookiesStorageData.getIsFavorite(coin.id),
    };
  },
  { selectResult: ({ coins: { entities } }, id) => entities[id] },
);
