import { createAppAsyncThunk } from "store/duck/types";

import { loadRegionsIsomorphic } from "./duck/operations";

export const loadRegions = createAppAsyncThunk(
  "regions/loadRegions",
  (_, { getState }) => {
    const {
      regions: { isLoaded, data },
    } = getState();

    if (isLoaded) {
      return data;
    }

    return loadRegionsIsomorphic();
  },
);
