import { createSlice } from "@reduxjs/toolkit";

import reducers from "./reducer";
import extraReducers from "./extraReducers";

import { PaymentState } from "./duck/types";

export const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    paymentMethod: null,
    isLoading: false,
    isLoaded: false,
    error: null,
    isDefault: false,
  } as PaymentState,
  reducers,
  extraReducers,
});
