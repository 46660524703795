import { RegionsBuilder } from "./duck/types";
import { loadRegionsServer } from "./serverThunks";
import { loadRegions } from "./thunks";

const extraReducers = (builder: RegionsBuilder) => {
  // #region load regions
  builder
    .addCase(loadRegions.pending, state => {
      state.error = null;
      state.isLoading = true;
    })
    .addCase(loadRegions.fulfilled, (state, action) => {
      state.isLoaded = true;
      state.isLoading = false;
      state.data = action.payload;
    })
    .addCase(loadRegions.rejected, (state, action) => {
      state.isLoaded = true;
      state.isLoading = false;
      state.error = action.error.message;
    });
  // #endregion

  // #region loadNestedRegions

  builder
    .addCase(loadRegionsServer.pending, state => {
      state.isLoading = true;
    })
    .addCase(loadRegionsServer.fulfilled, (state, action) => {
      state.isLoaded = true;
      state.isLoading = false;
      state.data = action.payload;
    })
    .addCase(loadRegionsServer.rejected, (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = action.error.message;
    });

  // #endregion
};

export default extraReducers;
